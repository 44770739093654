import { find, findAll, on } from "@elements/dom-utils";

const targetTopThreshold = 210;
export function init () {
    const anchors = findAll('.js-anchor-nav__item');
    const navbarAnchorLinks = findAll('.js-anchor-nav__navbar-link');
    const isPimcore = findAll('.pimcore_area_content').length > 0;

    let currentHighlightedAnchor = null;

    on('scroll', () => {
        let highlightedAnchor = null;
        anchors.forEach(anchor => {
            const sibling = isPimcore ? anchor.parentElement.nextElementSibling : anchor.nextElementSibling;
            if (anchor.getBoundingClientRect().top < targetTopThreshold && sibling.getBoundingClientRect().bottom > 0) {
                highlightedAnchor = anchor;
            }
        });

        if(currentHighlightedAnchor !== highlightedAnchor) {
            currentHighlightedAnchor = highlightedAnchor;
            navbarAnchorLinks.forEach(navbarAnchor => { navbarAnchor.classList.remove('anchor-nav__item--highlighted'); });

            if(highlightedAnchor) {
                const highlightedAnchorId = highlightedAnchor.getAttribute('id');
                const navbarAnchorToHighlight = find('[data-anchor-nav-navbar-link="'+highlightedAnchorId+'"]');

                navbarAnchorToHighlight.classList.add('anchor-nav__item--highlighted');
            }
        }
    }, window);

    const backToTopLinks = findAll('.js-anchor-nav__back-to-top');
    backToTopLinks.forEach(backToTopLink => {
        on('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, backToTopLink);
    });
}