import './scripts/common';

import 'bootstrap/js/dist/collapse';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass: 'icon icon-arrow-down-light'
});

import * as anchorNav from '@elements/anchor-nav';
anchorNav.init(
    {
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link mega-nav__item-content mega-nav__item-content--level-0 mega-nav__item-content--link js-anchor-nav__navbar-link" href="#' + anchor + '" data-anchor-nav-navbar-link="' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
        }
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

import * as anchorNavHighlighting from './scripts/anchor-nav-highlighting';
anchorNavHighlighting.init();

import * as video from './scripts/video';
video.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();



